'use strict';

angular.module('informaApp')
    .directive('infDrugIndicationPopup', function (BiomedtrackerUrlBuilder, ConstantsSvc, OmnitureSvc, DrugLevelPopupHelper) {
        return {
            restrict: 'E',
            templateUrl: 'directives/inf-drugIndicationPopup/template.ptl.html',
            scope: {
                options: "=",
                getSource: "=",
                exportSource: "=",
                phase: "=",
                popupTitle: "=",
                updateDrugs: "=",
                hiddenDrugsForItem: "="
            },
            getLinks: function (source) {
                if (!source) {
                    return
                }

                source.map(function (drug) {
                    drug.arr.map(function (indication, index) {
                        if (index === 0) {
                            drug.drugLink = BiomedtrackerUrlBuilder.buildDrugUrl(indication.drugId)
                        }

                        indication.indicationLink = BiomedtrackerUrlBuilder.buildIndicationUrl(indication.indicationId);
                        indication.targetsLinks = indication.targets.map(function (target) {
                            return BiomedtrackerUrlBuilder.buildTargetUrl(target.id)
                        });

                        indication.eventHistoryLink = BiomedtrackerUrlBuilder.buildEventHistoryUrl(indication.id);

                        return indication;
                    });
                    return drug;
                });
            },
            alignHeaderHeights: function (element) {
                var row = element.find('.modal-body .table-header .table-row');

                var cells = row.find('.cell-content');

                var source = cells.toArray().map(function (x) {
                    return $(x).height();
                });

                var max = _.max(source);

                cells.height(max);
            },
            addPaddingInHeader: function (element) {
                var tableBody = element.find('.modal-body .table-body');

                if (tableBody[0].scrollHeight > tableBody.height()) {
                    element.find('.modal-body .table-header .table-row').css("padding-right", "15px");
                    element.find('.modal-body .table-body .table-row .cell-container:last-child').css("border-right", "none");
                } else {
                    element.find('.modal-body .table-header .table-row').css("padding-right", "");
                    element.find('.modal-body .table-body .table-row .cell-container:last-child').css("border-right", "");
                }
            },
            resizeActiveBar: function (element, phaseIndex) {
                var bar = element.find('.active-bar');
                element.find('.active-bar').show();

                var tableBody = element.find(".modal-body .table-body");

                const activeHeader = $(element.find(".modal-body .table-header .cell-container.phase-header")[phaseIndex]);
                var offsetLeft = activeHeader.offset().left;
                bar.offset({left: offsetLeft});

                var height = tableBody[0].clientHeight;
                bar.height(height);

                var width = activeHeader[0].clientWidth;
                bar.width(width);
            },
            hidePhaseColumnSelection: (element) => {
                element.find('.active-bar').hide();
            },
            filterSource: function (data, hidden) {
                var filtered = [];

                data.forEach(function (item) {
                    for (var i = 0; i < filtered.length; i++) {
                        if (filtered[i].drugName === item.drugName) {
                            filtered[i].arr.push(item);
                            return;
                        }
                    }
                    filtered.push({
                        drugName: item.drugName,
                        drugId: item.drugId,
                        arr: [item],
                        hidden: (function () {
                            for (var i = 0; i < hidden.length; i++) {
                                if (hidden[i] === item.drugId) return true;
                            }
                            return false;
                        })()
                    });
                });

                return filtered;
            },
            orderSource: function (scope) {
                scope.sortField = null;

                const isAsc = true;
                return scope.sortings.phase.sort(isAsc, scope.phase);
            },
            setNewSource: function (getSource, scope) {
                if (!getSource) {
                    return
                }

                var _this = this;

                scope.processing = true;

                getSource(function (data) {
                    scope.source = _this.filterSource(data, scope.hiddenDrugsForItem);

                    scope.source = _this.orderSource(scope);
                    _this.getLinks(scope.source);
                });
            },
            setUpOptions: function (scope, element) {
                var _this = this;

                if (scope.options == null) {
                    return;
                }

                scope.options.show = function () {
                    var modal = element.find(".drug-indication-popup");
                    modal.modal({backdrop: 'static', keyboard: false});
                    scope.drawing = true;

                    _this.resetPopup(scope, element);

                    modal.on("shown.bs.modal", function fn() {
                        _this.alignHeaderHeights(element);

                        _this.setNewSource(scope.getSource, scope, element);

                        scope.hiddens = angular.copy(scope.hiddenDrugsForItem);

                        _this.resetPopup(scope, element);
                        modal.off("shown.bs.modal", fn);
                    });

                    modal.on("hidden.bs.modal", function fn() {
                        scope.source = null;

                        modal.off("hidden.bs.modal", fn);
                    });
                };

                scope.options.hide = function () {
                    var modal = element.find(".drug-indication-popup");
                    modal.modal("hide");
                }
            },
            resetPopup: (scope, element) => {
                element.find('.modal-content').css('width', '');
                element.find('.modal-content').css('height', '');
                element.find('.modal-content').css('left', '');
                element.find('.modal-content').css('top', '');
                scope.resetZoom();
                scope.fitTableBodyInModalHeight();
            },
            link: function (scope, element, attr) {
                const originalZoom = 1;

                var _this = this,
                    modal = element.find(".drug-indication-popup");

                function waitWhileTableBodyIsDrawing(callback) {
                    if (element.find(".modal-body .table-body")[0].clientHeight > 0) {
                        callback();
                        return;
                    }

                    setTimeout(function () {
                        waitWhileTableBodyIsDrawing(callback);
                    }, 500);
                }

                const popupClasses = {
                    tableBody: 'table-body',
                    zoomContainer: 'zoom-container',
                    modalHeader: 'modal-header',
                    modalFooter: 'modal-footer',
                    tableHeader: 'table-header',
                    scrollContainer: 'scroll-container'
                };

                scope.resizeOptions = {
                    minWidth: 400,
                    minHeight: 300
                };

                scope.resetZoom = () => {
                    const zoomContainer = element.find(`.${popupClasses.zoomContainer}`);
                    zoomContainer.css({zoom: originalZoom});
                };

                scope.fitTableBodyInModalHeight = fitTableBodyInModalHeight;

                scope.onExitToBiomedtracker = () => {
                    OmnitureSvc.exitToBiomedtracker.trackFromDrugLevelPopup();
                };

                scope.sortField = null;
                scope.sortAsc = true;

                scope.dateFormat = ConstantsSvc.Dates.fullMomentFormat;

                scope.isExporting = false;

                scope.drawEnd = function () {
                    scope.processing = false;

                    waitWhileTableBodyIsDrawing(function () {
                        _this.addPaddingInHeader(element);
                        _this.resizeActiveBar(element, scope.phase);
                        scope.drawing = false;
                        scope.$digest();
                    })
                };

                scope.getPhaseNumber = function () {
                    var phases = ["Phase I", "Phase II", "Phase III", "NDA / BLA"];
                    return phases[scope.phase];
                };

                scope.$watch("options", function () {
                    _this.setUpOptions(scope, element);
                });

                scope.phaseToString = function (phase) {
                    return phase == null
                        ? "Null"
                        : phase ? "Success" : "Failed";
                };

                scope.phaseDurationToString = function (duration) {
                    return duration == null
                        ? ""
                        : "(" + duration + " yrs)";
                };

                scope.select = function (drug) {
                    drug.hidden = !drug.hidden;

                    if (drug.hidden) {
                        scope.hiddens.push(drug.drugId);
                    } else {
                        scope.hiddens.forEach(function (item, i, arr) {
                            if (item === drug.drugId) {
                                arr.splice(i, 1)
                            }
                        });
                    }
                };

                scope.updateDrugsButton = function () {
                    scope.processing = true;

                    scope.hiddenDrugsForItem = scope.hiddens;

                    return scope.updateDrugs(scope.hiddenDrugsForItem)
                        .then(function () {
                            scope.processing = false;
                            modal.modal('hide');
                        });
                };

                scope.exportExcel = function () {
                    // modal.modal('hide');
                    scope.exportSource(function () {
                        modal.modal('hide');
                    });
                };

                scope.phases = {
                    phase1: 0,
                    phase2: 1,
                    phase3: 2,
                    ndabla: 3
                };

                scope.sortings = {
                    phase: {
                        key: 'phase',
                        sort:  (isAsc, phaseIndex) => {
                            scope.phase = phaseIndex;

                            this.resizeActiveBar(element, phaseIndex);
                            return DrugLevelPopupHelper.sortDrugLevelListByPhase(scope.source, scope.phase);
                        }
                    },
                    drugName: {
                        key: 'drugName',
                        sort: (isAsc) => {
                            scope.phase = null;
                            this.hidePhaseColumnSelection(element);

                            return _.orderBy(scope.source, ['drugName'], isAsc ? 'asc' : 'desc');
                        }
                    },
                };

                scope.sortSource = (sorting, ...args) => {
                    if (!scope.source || !scope.source.length) {
                        return false;
                    }

                    setSortDirection(sorting);
                    scope.currentSorting = sorting;

                    scope.source = sorting.sort(scope.sortAsc, ...args);
                };

                const scrollSize = window.getScrollSize();

                scope.onResize = (newSize) => {
                    const zoom = zoomPopupContentDependsOnWidth(newSize.width);
                    fitTableBodyInModalHeight(zoom);

                    this.resizeActiveBar(element, scope.phase);
                };

                function setSortDirection(sorting) {
                    if (scope.currentSorting === sorting) {
                        scope.sortAsc = !scope.sortAsc;
                    } else {
                        scope.sortAsc = true;
                    }
                }

                function fitTableBodyInModalHeight(zoom) {
                    element.find(`.${popupClasses.tableBody}`).innerHeight(calculateTableBodyHeight(zoom));
                }

                function zoomPopupContentDependsOnWidth(width) {
                    const borderWidth = 1000;
                    const reducedZoom = 0.7;

                    const zoomContainer = element.find(`.${popupClasses.zoomContainer}`);

                    const zoom = width < borderWidth ? reducedZoom : originalZoom;
                    zoomContainer.css({zoom});

                    return zoom;
                }

                function calculateTableBodyHeight(zoom) {
                    const getHeight = (className) => element.find(`.${className}`).outerHeight();

                    const modalHeaderHeight = getHeight(popupClasses.modalHeader);
                    const modalFooterHeight = getHeight(popupClasses.modalFooter);
                    const tableHeaderHeight = getHeight(popupClasses.tableHeader);

                    const scrollContainer = element.find(`.${popupClasses.scrollContainer}`)[0];
                    const scrollHeight = scrollContainer.scrollWidth > scrollContainer.clientWidth ? scrollSize : 0;

                    const zoomMeasurementError = !zoom || zoom === originalZoom ? 0 : 10; //TODO: temp, investigate and remove;

                    return getHeight(popupClasses.zoomContainer) - modalHeaderHeight - modalFooterHeight - tableHeaderHeight - scrollHeight - zoomMeasurementError;
                }

                this.setUpOptions(scope, element);
            }
        }
    });
